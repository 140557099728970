import React, { useRef, useState } from "react";
import SearchBar from "./components/SearchBar";
import shuffle from "shuffle-array";

interface result {
  category: string;
  type: string;
  difficulty: string;
  question: string;
  correct_answer: string;
  incorrect_answers: string[];
  answers: string[];
  image: string;
}
interface data {
  response_code: number;
  results: result[];
}

export const App = () => {
  const domain = "coolquizzes.xyz";
  const [query, setQuery] = useState("");
  const divRef = useRef<any>(null);
  const formRef = useRef<any>(null);
  const [data, setData] = useState<data | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [played, setPlayed] = useState(false);
  const [won, setWon] = useState(false);
  const categories = [
    "animals",
    "art",
    "celebrity",
    "entertainment",
    "geography",
    "history",
    "knowledge",
    "mythology",
    "politics",
    "science",
    "sports",
    "vehicles",
  ];
  const [result, setResult] = useState<result>();

  const getQuiz = () => {
    async function parse() {
      setIsFetching(true);
      await fetch(`https://opentdb.com/api.php?amount=1`)
        .then((res) => res.json())
        .then(
          (result) => {
            let r = result;
            r.results[0]["answers"] = r.results[0].incorrect_answers.concat([
              r.results[0].correct_answer,
            ]);
            r.results[0]["image"] = "/i/knowledge.png";
            categories.forEach((cat, i) => {
              if (r.results[0].category.toLowerCase().includes(cat)) {
                r.results[0]["image"] = `/i/${cat}.png`;
              }
            });
            shuffle(r.results[0]["answers"]);
            setData(r);
            setResult(r.results[0]);
            setPlayed(false);
            setWon(false);
            setIsFetching(false);
          },
          (error) => {
            setIsFetching(false);
          }
        );
    }
    if (!isFetching) {
      parse();
    }
  };

  const returnDangerousHTML = () => {
    if (result) {
      return { __html: result.question };
    }
  };

  return (
    <div className="w-screen h-screen overflow-none bg-gray-200">
      <div
        ref={divRef}
        className={`w-full h-full flex flex-col items-center justify-center z-50`}
      >
        <h1 className="-mt-48 text-lg font-extrabold mb-4 text-pink-400">
          {domain.replace(".xyz", "")}
        </h1>
        <div className="mb-24 overflow-hidden flex items-center justify-center w-full max-w-5xl">
          <div className="w-full max-w-5xl p-3 mt-4 bg-white rounded-lg shadow-2xl select-none">
            <div
              className={`text-base font-light text-center text-gray-900 ${
                data ? "border-b pb-2 mb-2" : ""
              }`}
            >
              Click on <span className="font-bold">&ldquo;Quiz&rdquo;</span> to
              get a random trivia question!
            </div>
            {result && (
              <div>
                <h5 className="mb-auto font-serif text-lg font-light tracking-widest text-center text-gray-600 bg-gray-300 rounded">
                  {result.category}
                </h5>
                <div className="flex items-stretch p-2">
                  <img
                    src={result.image}
                    alt={result.category}
                    className="w-40 h-40 my-auto mr-5"
                  />
                  <div className="flex-1 h-full pl-5 border-l">
                    <h5
                      className="mb-3 font-serif text-base font-bold text-gray-800"
                      dangerouslySetInnerHTML={returnDangerousHTML()}
                    ></h5>
                    {!played ? (
                      <div className="flex flex-col justify-end h-32">
                        {result.answers.map((answer, i) => {
                          return (
                            <div
                              className={`cursor-pointer text-gray-700 font-semibold py-1 px-3 transition-all hover:bg-gray-400 hover:text-gray-50 ${
                                i % 2 === 0 ? "bg-gray-300" : "bg-gray-200"
                              }`}
                              key={i}
                              onClick={() => {
                                setPlayed(true);
                                setWon(answer === result.correct_answer);
                              }}
                            >
                              {answer}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center h-32">
                        {won ? (
                          <div className="font-bold text-center text-green-800 text-7xl animate-bounce">
                            You Won!
                          </div>
                        ) : (
                          <div className="font-bold text-center text-red-800 text-7xl animate-bounce">
                            You Lost!
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <form
          ref={formRef}
          className="z-10 w-full px-5"
          action={`https://${domain}/s`}
          method="GET"
        >
          <div className="relative flex flex-row w-full max-w-5xl mx-auto transition-all bg-white rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
            <SearchBar formRef={formRef} query={query} setQuery={setQuery} />
            <button
              className="flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              className={`flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none`}
              type="button"
              onClick={getQuiz}
            >
              {isFetching ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-6 h-6 ${played ? "animate-bounce" : ""}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              )}
              {isFetching ? "Loading" : "Quiz"}
            </button>
          </div>
        </form>
        <div className="absolute bottom-0 right-auto text-black font-bold left-auto flex items-center justify-center">
          <a
            href={`https://${domain}/privacy`}
            className="text-xs mx-2 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          &bull;
          <a
            href={`https://${domain}/tos`}
            className="text-xs mx-2 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            TOS
          </a>
        </div>
      </div>
    </div>
  );
};
